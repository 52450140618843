import axios from "axios";

export const getSearchByMapList = async (
  dataRequest: SearchMapViewReq
): Promise<SearchByRoomOccupancyResponseType | null> => {
  try {
    const response = await axios.post(`/api/mapList/mapList`, {
      ...dataRequest,
    });

    return response.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getHolidaySearchByMapList = async (
  dataRequest: any
): Promise<SearchByRoomOccupancyHolidayResponseType | null> => {
  try {
    const response = await axios.post(`/api/mapList/mapList`, {
      ...dataRequest,
    });

    return response.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
