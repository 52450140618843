import { getSearchHotelFilterOrderBy } from "@lib/api/getSearchHotelFilterOrderBy";
import { create } from "zustand";
import { FilterInfo } from "data/FilterInfo";
import { paginator } from "@helpers/helper";
import { getRecommendedHotelsV2 } from "@lib/api/getRecommendedHotels";
// import { getLandmark } from "@lib/api/getHotelLandmarks";
import { getSearchByMapList } from "@lib/api/getSearchByMapList";
import { checkCookies, getCookie } from "cookies-next";
import { getLandmarksByCoordinates } from "@lib/api/getLandmarksByCoordinates";
import { fetcherHotelChain } from "@lib/api/getHotelChain";
export const LIMIT = 50;
export const PER_PAGE = 10;
const orderByInit = FilterInfo.find((f) => f);

export const discountRangeInit = [
  {
    name: "holiday_stay",
    from: 0,
    to: 100,
  },
  {
    name: "great_deal",
    from: 41,
    to: 100,
  },
  {
    name: "deals",
    from: 11,
    to: 40,
  },
  {
    name: "offers",
    from: 1,
    to: 10,
  },
];
const filterInit = {
  amenities: [],
  attractions: { distance: "5", id: "", lat_long: "" },
  chain_id: [],
  customer_rating: [],
  hotel_id: [],
  hotel_name: [],
  hotel_type: null, //rev
  limit: LIMIT, //rev
  maximum_price: 0,
  minimum_price: 0,
  offset: 0, //rev
  property_types: [],
  sort_by: "desc", //rev
  sort_on: "saving_percentage", //rev
  star_rating: [],
  themes: [],
  discount_range: discountRangeInit,
};

type FetchFilterType = {
  req: HotelSearchFilterQueryParamsType;
  showMap?: boolean;
  isChangeFilter?: boolean;
  isRecommendedHotel?: boolean;
  isInit?: boolean;
  ltlg?: string;
  starRating?: number;
};
interface HotelSearchDetailState {
  showMap: boolean;
  hotelNameSelected: string;
  hotelNameSimilar: string;
  cityName: string;
  selectedLandMark: selectedLandMarkProps;
  dataHotels: HotelType[];
  dataCards: HotelType[];
  recommendedCards: HotelType[];
  page: number;
  offset: number;
  uid: string;
  filters: GeneralFilterType;
  orderBy: OrderByType;
  total: number;
  totalgd: number;
  totalgdInit: number;
  isLoading: boolean;
  rangePriceInit: number[];
  rangeRatingInit: number[];
  rangeStarRatingInit: number[];
  hotelChainSelected: number[];
  landmarksData: HotelLandmark[];
  hotelChainData: HotelChainType[] | null;
  landmarksSelected: HotelLandmark | null;
  searchHotelName: string;
  amenityData: AmenityType[];
  themeData: ThemesType[];
  amenitySelected: AmenityType[];
  themeSelected: ThemesType[];
  searchHotelChain: string;
  searchHotelLandmark: string;
  searchId: string;
  searchText: string;
  showLoadMore: boolean;
  backFromMapView: boolean;
  UID: string;
  rangePrice: ActuallyPriceRange;
  setRangePrice: (val: ActuallyPriceRange) => void;
  setUID: (value: string) => void;
  setBackFromMapView: (value: boolean) => void;
  setShowMap: (value: boolean) => void;
  setHotelNameSelected: (value: string) => void;
  setHotelNameSimilar: (value: string) => void;
  setSelectedLandMark: (value: selectedLandMarkProps) => void;
  setDataHotels: (value: HotelType[]) => void;
  setDataCards: (value: HotelType[]) => void;
  setPage: (value: number) => void;
  setUid: (value: string) => void;
  setOffset: (value: number) => void;
  setOrderBy: (value: OrderByType) => void;
  fetchFilter: (dataFilter: FetchFilterType) => void;
  setTotal: (value: number) => void;
  setTotalgd: (value: number) => void;
  setTotalgdInit: (value: number) => void;
  setIsLoading: (value: boolean) => void;
  setCityName: (value: string) => void;
  setRangePriceInit: (value: number[]) => void;
  setRangeRatingInit: (value: number[]) => void;
  setRangeStarRatingInit: (value: number[]) => void;
  setFilter: (filters?: GeneralFilterType) => void;
  fetchRecommendedHotels: (value: RecommendedHotelsReqType) => void;
  setRecommendedCards: (value: HotelType[]) => void;
  clearRecommendedCards: () => void;
  clearDataCards: () => void;
  clearDataHotels: () => void;
  setHotelChainSelected: (id: number[]) => void;
  fetchLandmarksData: (
    text: string,
    latitude: string,
    longitude: string,
    unique_id: string
  ) => void;
  fetchHotelChain: (val: string, unique_id: string) => void;
  setHotelLandmarkSelected: (value: HotelLandmark | null) => void;
  setSearchHotelName: (value: string) => void;
  setAmenityData: (value: AmenityType[]) => void;
  setThemeData: (value: ThemesType[]) => void;
  setAmenitySelected: (value: AmenityType) => void;
  setThemeSelected: (value: ThemesType) => void;
  resetFilter: () => void;
  setSearchHotelChain: (value: string) => void;
  setSearchHotelLandmark: (value: string) => void;
  getFilterReset: () => GeneralFilterType;
  getFilterStatus: () => { status: boolean; count: number };
  fetcherMapList: (
    searchRequest: RequestSearchByRoomOccupancy | null,
    points: number[]
  ) => void;
  setSearchId: (value: string) => void;
  setSearchText: (value: string) => void;
  setShowLoadMore: (value: boolean) => void;
  resetHotelSearchByRoomOcupancyHotelCards: () => void;
}

export const useHotelSearchDetailStore = create<HotelSearchDetailState>(
  (set, get) => ({
    showMap: false,
    hotelNameSelected: "",
    hotelNameSimilar: "",
    selectedLandMark: {
      name: "",
      lat_long: "",
    },
    dataHotels: [],
    dataCards: [],
    recommendedCards: [],
    page: 1,
    offset: 1,
    uid: "",
    filters: filterInit,
    orderBy: {
      key: orderByInit?.key || "",
      order: orderByInit?.order || "",
    },
    total: 0,
    totalgd: 0,
    totalgdInit: 0,
    isLoading: true,
    cityName: "",
    rangePriceInit: [0, 0],
    rangeRatingInit: [0, 5],
    rangeStarRatingInit: [0, 5],
    hotelChainSelected: [],
    landmarksData: [],
    hotelChainData: [],
    landmarksSelected: null,
    searchHotelName: "",
    searchHotelChain: "",
    searchHotelLandmark: "",
    amen: [],
    amenityData: [],
    themeData: [],
    amenitySelected: [],
    themeSelected: [],
    searchId: "",
    searchText: "",
    showLoadMore: true,
    backFromMapView: false,
    UID: "",
    rangePrice: { max: 0, min: 0 },
    setRangePrice: (value: ActuallyPriceRange) => {
      set({ rangePrice: value });
    },
    setUID: (value: string) => {
      set({ UID: value });
    },
    setBackFromMapView: (value: boolean) => {
      set({ backFromMapView: value });
    },
    setShowMap: (value: boolean) => {
      set({ showMap: value });
    },
    setHotelNameSelected: (value: string) => {
      set({ hotelNameSelected: value });
    },
    setHotelNameSimilar: (value: string) => {
      set({ hotelNameSimilar: value });
    },
    setSelectedLandMark: (value: selectedLandMarkProps) => {
      set({ selectedLandMark: value });
    },
    setDataHotels: (value: HotelType[]) => {
      const { dataHotels } = get();
      const aux = [...dataHotels];
      set((state) => ({ ...state, dataHotels: aux.concat(value) }));
    },
    setDataCards: (value: HotelType[]) => {
      //   const { dataCards } = get();
      //   const aux = [...dataCards];
      //   set((state) => ({ ...state, dataCards: aux.concat(value) }));
      set((state) => ({ ...state, dataCards: value }));
    },

    setPage: (value: number) => {
      set({ page: value });
    },
    setUid: (value: string) => {
      set({ uid: value });
    },
    setOffset: (value: number) => {
      set({ offset: value });
    },
    setOrderBy: (value: OrderByType) => {
      set({ orderBy: value });
    },
    setTotal: (value: number) => {
      set({ total: value });
    },
    setTotalgd: (value: number) => {
      set({ totalgd: value });
    },
    setTotalgdInit: (value: number) => {
      set({ totalgdInit: value });
    },
    setIsLoading: (value: boolean) => {
      set({ isLoading: value });
    },
    setCityName: (value: string) => {
      set({ cityName: value });
    },
    setRangePriceInit: (value: number[]) => {
      set({ rangePriceInit: value });
    },

    setRangeRatingInit: (value: number[]) => {
      set({ rangeRatingInit: value });
    },
    setRangeStarRatingInit: (value: number[]) => {
      set({ rangeStarRatingInit: value });
    },
    setFilter: (value?: GeneralFilterType) => {
      const { filters } = get();
      set({
        filters: {
          ...filters,
          ...value,
        },
      });
    },
    fetchFilter: async ({
      req,
      showMap,
      isChangeFilter,
      isRecommendedHotel,
      isInit,
      ltlg,
      starRating,
    }: FetchFilterType) => {
      const { total, totalgd, totalgdInit } = get();
      let tempTotal = total;
      set({ isLoading: true });
      set({ total: 0 });

      const prev_unique_id: string = String(
        localStorage.getItem("hlt_unique_id") || ""
      ).trim();
      if (prev_unique_id) {
        set({ dataHotels: [] });
        set({ dataCards: [] });
      }

      const { dataHotels, dataCards, page, fetchRecommendedHotels } = get();

      let auxHotels = [...dataHotels];

      let nextPage = page + 1;
      if (isChangeFilter) {
        auxHotels = [];
        nextPage = 1;
      }

      const totalHotels = dataHotels.length;
      const totalCards = dataCards.length;
      let totalFilter: number = 0;
      let data: HotelType[] = [];
      if ((totalHotels == totalCards || isChangeFilter) && !isInit) {
        const response = await getSearchHotelFilterOrderBy(req);
        data = response?.result?.hotels || [];
        if (response?.result && Array.isArray(data) && data.length > 0) {
          auxHotels = auxHotels.concat(data);
          let currentTotal = totalgdInit;
          if (checkCookies("htlgd")) {
            if (totalgdInit == 0) {
              set({ totalgdInit: totalgd });
              currentTotal = totalgd;
            }

            set({
              totalgd: currentTotal + Number(response.result.totalcountgd),
              dataHotels: auxHotels,
              total: currentTotal + Number(response.result.totalcountgd),
              showLoadMore: false,
            });

            totalFilter = Number(response.result.totalcount || 0);
          } else {
            set({
              dataHotels: auxHotels,
              total: response.result.totalcount,
              showLoadMore: data.length > 10 ? true : false,
            });
            totalFilter = Number(response.result.totalcount || 0);
          }
        } else {
          set({ dataHotels: [], dataCards: [], total: 0, showLoadMore: false });
        }
      }
      const dataPaginator = paginator(
        auxHotels,
        1,
        showMap ? 50 : nextPage * 10
      );

      const findHotel = data.find((f) => f);
      if (!isInit) {
        set({
          page: nextPage,
          dataCards: dataPaginator.data,
          total: totalFilter ? totalFilter : tempTotal,
        });
        set({ isLoading: false });
      }

      if ((isRecommendedHotel && findHotel) || isInit) {
        const filterAux = req?.filterreq?.filter;
        let hotelNameAux = "";
        let hotelIdAux = 0;
        if (
          filterAux &&
          Array.isArray(filterAux.hotel_name) &&
          Array.isArray(filterAux.hotel_id)
        ) {
          hotelNameAux = filterAux.hotel_name[0];
          hotelIdAux = filterAux.hotel_id[0];
        }

        const starRatingAux = findHotel?.star_rating || starRating;
        const destinationName = findHotel?.hotel_name || hotelNameAux;
        const vid = findHotel?.vid || hotelIdAux;
        const ltlgAux = findHotel
          ? `${findHotel.latitude},${findHotel.longitude}`
          : ltlg;

        set({ hotelNameSimilar: destinationName, showLoadMore: false });
        set({
          total: totalFilter ? totalFilter : tempTotal,
        });

        let recommendedReq: RecommendedHotelsReqType = {
          check_in_date: String(req.filterreq.check_in_date),
          check_out_date: String(req.filterreq.check_out_date),
          currency: String(req.filterreq.currency),
          destination_name: String(destinationName),
          discount_range: req.filterreq.filter.discount_range || [],
          hotel_info: {
            vid: Number(vid),
            star_rating: Number(starRatingAux),
            ltlg: String(ltlgAux),
          },
          language: String(req.filterreq.language),
          nationality: String(req.filterreq.nationality),
          rooms: req.filterreq.rooms || [],
          traveller_id: req.filterreq.traveller_id || null,
          uidreq: String(req.uidreq),
        };

        if (isInit) {
          set({ isLoading: true });
          recommendedReq = {
            ...recommendedReq,
            uidreq: undefined,
            discount_range: undefined,
          };
        }
        fetchRecommendedHotels(recommendedReq);
      }
    },
    fetchRecommendedHotels: async (dataReq: RecommendedHotelsReqType) => {
      set({ isLoading: true });

      const response = await getRecommendedHotelsV2(dataReq);
      const data = response?.result?.hotels;
      if (data && Array.isArray(data)) {
        const aux = data.map((d, index) => ({
          ...d,
          recomemded: {
            index: index + 1,
            isRecommended: true,
          },
        }));
        set({ recommendedCards: aux });
      }
      set({ isLoading: false });
    },

    setRecommendedCards: async (data: HotelType[]) => {
      set({ isLoading: true });
      set({ recommendedCards: data });
      set({ isLoading: false });
    },
    clearRecommendedCards: () => {
      set({ recommendedCards: [] });
    },
    clearDataCards: () => {
      set({ dataCards: [] });
    },
    clearDataHotels: () => {
      set({ dataHotels: [] });
    },
    setHotelChainSelected: async (id: number[]) => {
      set({ hotelChainSelected: id });
    },

    fetchHotelChain: async (val: string, unique_id: string) => {
      const data = await fetcherHotelChain({ key: val, unique_id });
      set({ hotelChainData: data });
    },

    fetchLandmarksData: async (
      text: string,
      latitude: string,
      longitude: string,
      unique_id: string
    ) => {
      const reqdata = {
        latitude: String(latitude),
        longitude: String(longitude),
        text: text || "",
        language: String(getCookie("lan_code")) || "en-US",
        unique_id: unique_id,
      };
      const data = await getLandmarksByCoordinates(reqdata);
      set({ landmarksData: data });
    },
    setHotelLandmarkSelected: async (value: HotelLandmark | null) => {
      set({ landmarksSelected: value });
    },
    setSearchHotelName: async (value: string) => {
      set({ searchHotelName: value });
    },
    setAmenityData(value: AmenityType[]) {
      set({ amenityData: value });
    },
    setThemeData(value: ThemesType[]) {
      set({ themeData: value });
    },
    setAmenitySelected(value: AmenityType) {
      const { amenitySelected } = get();
      const find = amenitySelected.find((f) => f.id == value.id);
      if (find) {
        const remove = amenitySelected.filter((f) => f.id != value.id);
        set({ amenitySelected: remove });
      } else {
        let newData: AmenityType[] = [...amenitySelected];
        newData = newData.concat([value]);
        set({ amenitySelected: newData });
      }
    },
    setThemeSelected(value: ThemesType) {
      const { themeSelected } = get();
      const find = themeSelected.find((f) => f.id == value.id);
      if (find) {
        const remove = themeSelected.filter((f) => f.id != value.id);
        set({ themeSelected: remove });
      } else {
        let newData: ThemesType[] = [...themeSelected];
        newData = newData.concat([value]);
        set({ themeSelected: newData });
      }
    },
    resetFilter() {
      const { setFilter, getFilterReset } = get();
      setFilter({
        ...getFilterReset(),
      });

      // * clear inputs
      set({
        searchHotelName: "",
        searchHotelChain: "",
        searchHotelLandmark: "",
        themeSelected: [],
        amenitySelected: [],
        landmarksSelected: null,
        hotelChainSelected: [],
      });
    },
    setSearchHotelChain: (value: string) => {
      set({ searchHotelChain: value });
    },
    setSearchHotelLandmark: (value: string) => {
      set({ searchHotelLandmark: value });
    },
    getFilterReset() {
      const { rangeStarRatingInit, rangePriceInit, rangeRatingInit } = get();
      return {
        offset: 0,
        minimum_price: rangePriceInit[0], // * range price
        maximum_price: rangePriceInit[1], // * range price
        customer_rating: rangeRatingInit,
        star_rating: rangeStarRatingInit,
        hotel_id: [], //* hotel name
        hotel_name: [], //* hotel name
        amenities: [],
        themes: [],
        chain_id: [],
        sort_by: "desc", //rev
        sort_on: "saving_percentage", //rev
        attractions: {
          //* landmark
          distance: "5",
          id: "",
          lat_long: "",
        },
      };
    },

    getFilterStatus() {
      const {
        searchHotelName,
        searchHotelChain,
        searchHotelLandmark,
        themeSelected,
        amenitySelected,
        landmarksSelected,
        hotelChainSelected,
      } = get();
      let count = 0;
      if (searchHotelName) {
        count += 1;
      }
      if (searchHotelChain) {
        count += 1;
      }
      if (searchHotelLandmark) {
        count += 1;
      }
      if (themeSelected.length > 0) {
        themeSelected.map(() => (count += 1));
      }
      if (amenitySelected.length > 0) {
        amenitySelected.map(() => (count += 1));
      }

      if (hotelChainSelected.length > 0) {
        hotelChainSelected.map(() => (count += 1));
      }
      if (landmarksSelected) {
        count += 1;
      }
      return {
        status: count > 0,
        count,
      };
    },
    fetcherMapList: async (
      searchRequest: RequestSearchByRoomOccupancy | null,
      points: number[]
    ) => {
      const { filters, searchId, uid, recommendedCards } = get();
      if (searchRequest && points.length == 4 && recommendedCards.length == 0) {
        const langCode = String(getCookie("lan_code")).split("-")[0];
        // set({
        //   dataHotels: [],
        //   dataCards: [],
        //   total: 0,
        // });
        const data = await getSearchByMapList({
          check_in_date: searchRequest.check_in_date,
          check_out_date: searchRequest.check_out_date,
          currency: searchRequest.currency,
          destination_name: searchRequest.destination_name,
          nationality: searchRequest.nationality,
          rooms: searchRequest.rooms,
          search_id: searchId,
          search_text: searchRequest.search_text,
          search_type: searchRequest.search_type,
          unique_id: uid,
          language: {
            code: langCode,
            language: String(getCookie("lan_name")),
          },
          point1: points[0],
          point2: points[1],
          point3: points[2],
          point4: points[3],
          filter: filters,
        });

        if (data?.result && Array.isArray(data.result.hotels)) {
          set({
            total: data.result.totalcount,
            dataCards: data.result.hotels,
            dataHotels: data.result.hotels,
          });
        } else {
          set({
            total: 0,
            dataCards: [],
            dataHotels: [],
          });
        }
      }
    },
    setSearchId(value: string) {
      set({ searchId: value });
    },
    setSearchText(value: string) {
      set({ searchText: value });
    },
    setShowLoadMore(value: boolean) {
      set({ showLoadMore: value });
    },
    resetHotelSearchByRoomOcupancyHotelCards() {
      set({
        isLoading: true,
        dataHotels: [],
        dataCards: [],
        recommendedCards: [],
        total: 0,
      });
    },
  })
);
