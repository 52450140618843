import axios from "axios";
import { LocalOverrides } from "./localOverride";

export const getSearchHotelFilterOrderBy = async (
  dataRequest: any
): Promise<SearchByRoomOccupancyResponseType | null> => {
  try {
    dataRequest = LocalOverrides.attachBypassInfo(dataRequest);
    const response = await axios.post(`/api/orderByFilter/orderByFilter`, {
      ...dataRequest,
    });
    return response.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getSearchHotelFilterHolidayOrderBy = async (
  dataRequest: any
): Promise<SearchByRoomOccupancyHolidayResponseType | null> => {
  try {
    dataRequest = LocalOverrides.attachBypassInfo(dataRequest);
    const response = await axios.post(`/api/orderByFilter/orderByFilter`, {
      ...dataRequest,
    });
    return response.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
