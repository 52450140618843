import { useHotel } from "@contexts/hotelContext";

export const useGlobalContext = (type = "hotel") => {
  switch (type) {
    case "hotel":
      return useHotel();
      break;

    default:
      throw new Error("Context doesn't exist");
      break;
  }
};
