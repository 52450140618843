import axios from "axios";
import { LocalOverrides } from "./localOverride";

export const getRecommendedHotels = async (
  vid: string,
  latitude: number,
  longitude: number,
  checkInDate: string,
  checkOutDate: string,
  rooms: any,
  nationality: string,
  currency: string,
  language: string,
  destinationName: string,
  starRating: number
): Promise<SearchByRoomOccupancyResponseType | null> => {
  let json = {
    hotel_info: {
      vid: vid,
      star_rating: starRating,
      ltlg: `${latitude},${longitude}`,
    },
    check_in_date: checkInDate,
    check_out_date: checkOutDate,
    rooms,
    nationality: nationality,
    currency: currency,
    language: language,
    traveller_id: null,
    destination_name: destinationName,
  };
  json = LocalOverrides.attachBypassInfo(json);
  try {
    const response = await axios.post("/api/booking/getRecommendedHotels", {
      json,
    });
    return response.data;
  } catch (error) {
    console.info(error);

    return null;
  }
};

export const getRecommendedHoliday = async (
  vid: string,
  latitude: number,
  longitude: number,
  checkInDate: string,
  checkOutDate: string,
  rooms: any,
  nationality: string,
  currency: string,
  language: string,
  destinationName: string,
  starRating: number
): Promise<SearchByRoomOccupancyHolidayResponseType | null> => {
  let json = {
    hotel_info: {
      vid: vid,
      star_rating: starRating,
      ltlg: `${latitude},${longitude}`,
    },
    check_in_date: checkInDate,
    check_out_date: checkOutDate,
    rooms,
    nationality: nationality,
    currency: currency,
    language: language,
    traveller_id: null,
    destination_name: destinationName,
  };

  try {
  json = LocalOverrides.attachBypassInfo(json);
    const response = await axios.post("/api/booking/getRecommendedHotels", {
      json,
    });
    return response.data;
  } catch (error) {
    console.info(error);

    return null;
  }
};

export const getRecommendedHotelsV2 = async (
  dataReq: RecommendedHotelsReqType
): Promise<SearchByRoomOccupancyResponseType | null> => {
  try {
    dataReq = LocalOverrides.attachBypassInfo(dataReq);
    const response = await axios.post("/api/booking/getRecommendedHotelsV2", {
      ...dataReq,
      limit: 10,
    });
    return response.data;
  } catch (error) {
    console.info(error);

    return null;
  }
};
