export const FilterInfo = [
  {
    id: 1,
    name: "Discount (High to Low)",
    order: "desc",
    key: "saving_percentage",
  },
  {
    id: 2,
    name: "Distance (Near to Far)",
    order: "asc",
    key: "distance",
  },
  {
    id: 3,
    name: "Guest Rating (High to Low)",
    order: "desc",
    key: "customer_rating",
  },
  {
    id: 4,
    name: "Price (Highest to Lowest)",
    order: "desc",
    key: "net_price",
  },
  {
    id: 5,
    name: "Price (Lowest to Highest)",
    order: "asc",
    key: "net_price",
  },
  {
    id: 6,
    name: "Star Rating (High to Low)",
    order: "desc",
    key: "star_rating",
  },
  {
    id: 7,
    name: "Star Rating (Low to High)",
    order: "asc",
    key: "star_rating",
  },
];

export const filterInfo2 = [
  {
    id: 2,
    name: "Distance (Near to Far)",
    order: "asc",
    key: "distance",
  },
  {
    id: 3,
    name: "Guest Rating (High to Low)",
    order: "desc",
    key: "customer_rating",
  },
  {
    id: 4,
    name: "Price (Highest to Lowest)",
    order: "desc",
    key: "net_price",
  },
  {
    id: 5,
    name: "Price (Lowest to Highest)",
    order: "asc",
    key: "net_price",
  },
  {
    id: 6,
    name: "Star Rating (High to Low)",
    order: "desc",
    key: "star_rating",
  },
  {
    id: 7,
    name: "Star Rating (Low to High)",
    order: "asc",
    key: "star_rating",
  },
];
