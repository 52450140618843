import axios from "axios";
import { LocalOverrides } from "./localOverride";

export const fetcherHotelChain = async (params: {
  key: string;
  unique_id: string;
}): Promise<HotelChainType[] | null> => {
  try {
  params = LocalOverrides.attachBypassInfo(params);
    const response = await axios.post(`/api/filter/getHotelChain`, {
      ...params,
    });
    return response?.data?.data?.records;
  } catch (error) {
    console.info(error);
    return null;
  }
};
