import axios from "axios";
import { LocalOverrides } from "./localOverride";

export const getLandmarksByCoordinates = async (obj: {
  latitude: string;
  longitude: string;
  text: string;
  language: string;
  unique_id: string | null;
}): Promise<HotelLandmark[]> => {
  try {
    obj = LocalOverrides.attachBypassInfo(obj);
    const response = await axios.post(
      `/api/filter/getLandmarkCoordinates`,
      obj
    );
    return response?.data?.result?.result;
  } catch (error) {
    console.info(error);
    return [];
  }
};
